<template>
  <div>
    <el-row :gutter='10'>
      <el-col :span='16'>
        <PlatformsRadioGroup :showAll='true' :platformCode='searchCondition.platform_code'
                             @choicePlatform='choicePlatform'></PlatformsRadioGroup>
      </el-col>
      <el-col :span='8' style='text-align: right'>
        <export-link ref='refExportLink' @export='exportExcel' class-name='export-excel'
                     :can-export="userPermissions.indexOf('dashboard_revenue_export') !== -1"
                     :lint-title='`导出表格`'></export-link>
        <ImageShare v-if="userPermissions.indexOf('dashboard_revenue_share') !== -1" :share-content-id='`shareContent`'
                    :btn-title='`分享榜单`' :dialog-title='`营收榜单分享图`'/>
        <el-link :underline='true' type='primary' class='share-rank' @click='goTrendDataList'
                 v-if="userPermissions.indexOf('revenue_data') !== -1"><i class='el-icon-s-grid'></i>详情
        </el-link>
        <el-tooltip class='item' effect='dark' content='无权限：查看营收数据列表' placement='left-start' v-else>
          <el-link :underline='false' type='info' class='export-excel'>
            <i class='el-icon-s-grid'></i>详情
          </el-link>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form :inline='true' size='small'>
          <el-form-item>
            <!--            <CompanySelect :multiple="true" @handleSelect='selectDept'/>-->
            <CompanySelect ref='refCompanySelect' :multiple='true' @handleSelect='selectDept'/>
            <!--            <DeptCascader ref='refDeptCascader' :multiple='true' :check-strictly='false' :collapse-tags='true'-->
            <!--                          :emit-path='false' @change='selectDept' />-->
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model='ym'
                type='month'
                format='yyyy年MM月'
                value-format='yyyy-MM'
                placeholder='选择月度' style='width: 140px' @change='changeMonth'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-popover
                placement='right'
                title=''
                width='500'
                trigger='click'
            >
              <el-button size='small' type='primary' slot='reference'>
                选择季度<span v-if='quarter.label'>: {{ quarter.label }}</span>
              </el-button>
              <el-row>
                <el-col :span='24'>
                  <el-radio-group v-model='quarter' @change='changeQuarter'>
                    <el-radio-button v-for='(quarter) in quarters' :label='quarter' :key='quarter.label'>
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model='year'
                type='year'
                format='yyyy年'
                value-format='yyyy'
                placeholder='选择年度' style='width: 140px' @change='changeYear'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <el-select v-model='filterArtist' filterable multiple collapse-tags placeholder='检索红人' clearable>
              <el-option
                  v-for='item in tableData'
                  :key='item.artist_id'
                  :label='item.nickname'
                  :value='item.artist_id'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-tooltip effect='dark' content='有临时权限且系统判定在公司内，可查看所有组数据，请注意数据保密！！！'
                        placement='top-start'
                        v-if="userPermissions.indexOf('temp_rv') !== -1">
              <span><i v-if='in_company' class='el-icon-location-outline'
                       style='color: #E6A23C;font-size: 18px'></i></span>
            </el-tooltip>
            <!--            <el-link :underline="false" type="info" style="font-size: 0.8rem">{{ conditionStr }}</el-link>-->
          </el-form-item>
          <el-form-item>
            <el-button @click='initData'>查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!--    表格-->
    <div id='shareContent'>
      <div class='default-table' id='my-rank'
           v-loading='loading'
           element-loading-text='数据加载中……'
           element-loading-spinner='el-icon-loading'>
        <el-table
            :data='tableData'
            :default-sort="{prop:'income_total', order:'descending'}"
            :row-class-name='rowClassName'
            :summary-method='getSummaries'
            show-summary
            border
            style='width: 100%'>
          <el-table-column
              prop='index'
              width='64'
              label='序号' align='center' fixed='left'>
          </el-table-column>
          <!--          <el-table-column-->
          <!--              prop="rank"-->
          <!--              label="排名(总营收)"-->
          <!--              width="80" align="center" sortable>-->
          <!--            <template slot="header">-->
          <!--              <span title="按总营收排名">排名</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column
              prop='group_name'
              label='部门-组'
              width='200' align='center' sortable>
            <template slot-scope='{row}'>
              <el-link type='primary' @click='goDeptDetail(row.group_id)'
                       v-if="userPermissions.indexOf('dept_detail')>-1"
                       target='_blank'>
                {{ row.group_name || '' }}
              </el-link>
              <span v-else>{{ row.group_name || '' }}</span>
              <!--              <DeptNameTag :info="{id:row.group_id,name:row.group_name}" />-->
            </template>
          </el-table-column>
          <el-table-column
              prop='nickname'
              label='红人昵称'
              width='160' show-overflow-tooltip align='center' header-align='center'>
            <template slot-scope='{row}'>
              <el-link :underline='true' type='primary' @click='jumpToKolDetail(row.artist_id)'
                       title='点击查看【红人详情】'>
                {{ row.nickname }}
              </el-link>
              <span style='color: #F56C6C' :title='`${$utils.parseTime(row.fired_at,"{y}-{m}-{d}")}`'>
                {{ row.sign_status === 'N' ? '『解约』' : '' }}
              </span>
            </template>
          </el-table-column>
          <template v-for='(column,index) in columns'>
            <el-table-column :prop='`${column.value}`' :key='index' :label='`${column.title}`'
                             :min-width='`${column.width}`' align='right' header-align='center' sortable>
              <template slot-scope='scope'>
                <span>{{ moneyFormat(scope.row[column.value] || 0) }}</span>
              </template>
            </el-table-column>
          </template>
          <el-table-column
              prop='contrast'
              label='变动比例(%)'
              width='160' show-overflow-tooltip align='center' header-align='center'>
            <template slot-scope='{row}'>

              <span v-if="row.type" style="color:#C73636 ">
                {{ row.contrast ? `↑ ${numberFormat(row.contrast, 2)}` : '' }}
              </span>
              <span v-else style="color:#465EB8 ">
                {{ row.contrast ? `↓ ${numberFormat(row.contrast, 2)}` : '' }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import ExportLink from '@/components/export/ExportLink'

export default {
  name: 'RevenueChart',
  components: { ExportLink, PlatformsRadioGroup },
  data() {
    return {
      loading: false,
      conditionStr: '',
      searchCondition: {
        depth: null,
        // group_id: null,
        group_range: null,
        year: null,
        platform_code: '',
        months: []
      },
      year: null,
      ym: null,
      rangYm: null,
      quarter: { label: '', value: [] },
      quarters: [
        { label: '暂时不选', value: [] },
        { label: '第一季度', value: [1, 2, 3] },
        { label: '第二季度', value: [4, 5, 6] },
        { label: '第三季度', value: [7, 8, 9] },
        { label: '第四季度', value: [10, 11, 12] }
      ],
      tableData: [],
      platforms: [],
      checkAll: false,
      checkedPlatforms: [],
      isIndeterminate: true,
      columns: [
        { value: 'income_3', title: '短视频佣金', width: 100 },
        { value: 'income_4', title: '直播佣金', width: 100 },
        { value: 'income_5', title: '直播坑位费', width: 100 },
        { value: 'commissionTotal', title: '当月合计', width: 100 }
      ],
      lastDate: '',
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      filterArtist: [],
      filterGroups: [],//过滤分组
      in_company: false,//是否在公司
      drawerVisible: false,
      drawerTableData: [],
      drawerTitle: '订单详情',
      lastData: [],
      newItems: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  created() {
    this.getLastMonth()
    this.getPlatforms()
  },
  mounted() {
    this.initData()
  },
  methods: {
    numberFormat,
    async init() {
      await this.getLastMonth()
      await this.getPlatforms()
      await this.initData()
    },
    rowDblclick() {
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1

      row.isShow = this.filterArtist && this.filterArtist.length > 0 ? this.filterArtist.indexOf(row.artist_id) > -1 : true
      if (!row.isShow) {
        return 'hidden-columns'//table.css
      }

      row.isShow = this.filterGroups && this.filterGroups.length > 0 ? this.filterGroups.indexOf(row.group_id) > -1 : true
      if (!row.isShow) {
        return 'hidden-columns'//table.css
      }

      if (row.is_empty) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },
    choicePlatform(val) {
      this.searchCondition.platform_code = val
      // console.log(this.searchCondition)
      this.initData()
    },
    async checkDetails(row) {
      if ((row.ad_artist_id || undefined) !== undefined) {
        let searchCondition = this.handleSearchCondition()
        if (searchCondition.months.length > 1) {
          this.$message.warning('暂不支持季度查询!')
          return false
        }
        this.drawerTableData = await this.$api.getRevenueOrderDetails({
          artist_id: row.ad_artist_id,
          month: searchCondition.months[0],
          year: searchCondition.year
        })
        this.drawerTitle = `${row.nickname + '的订单详情-' + searchCondition.year + '年' + searchCondition.months[0] + '月'}`
        this.drawerVisible = true
      } else this.$message.warning('未在系统比对到红人!')
    },
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson && platformsJson.indexOf('{') > -1) {
        this.platforms = JSON.parse(platformsJson)
      }

      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

    },
    getLastMonth() {
      this.ym = this.$utils.getLastMonth()
      let ymArr = this.ym.split('-')
      this.searchCondition.year = ymArr[0] * 1//年份
      this.searchCondition.months = [(ymArr[1]) * 1]//月份的集合
    },
    async getOverData() {
      let lastMonth = []
      let lastSearchCondition = JSON.parse(JSON.stringify(this.searchCondition))
      if (lastSearchCondition.months && lastSearchCondition.months.length > 0) {
        if (this.quarter.value.length !== 0) {
          //是对比季度，不是对比上月
          lastSearchCondition.months.forEach((i) => {
            if ([1, 2, 3].includes(i)) {
              lastMonth = [10, 11, 12]
            } else {
              lastMonth.push(i - 3)
            }
          })
          let isFrist = lastSearchCondition.months.find((i) => {
            return i === 1
          })
          if (isFrist) {
            lastSearchCondition.year = lastSearchCondition.year - 1
          }
        } else {
          lastSearchCondition.months.forEach((i) => {
            if (i !== 1) {
              lastMonth.push(i - 1)
            } else {
              lastMonth.push(12)
            }
          })
          if (lastMonth[0] === 1) {
            lastSearchCondition.year = lastSearchCondition.year - 1
          }
        }
      }
      lastSearchCondition.months = lastMonth
      let { list } = await this.$api.getRevenueDatumRankData(lastSearchCondition)
      this.lastData = list
    },
    async initData() {
      this.tableData = []
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.year) {
        // Object.assign(searchCondition, {})
        this.loading = true
        //已录入的数据
        let { list, in_company } = await this.$api.getRevenueDatumRankData(searchCondition)
        await this.getOverData()
        list.forEach(item => {
          let commissionTotal = 0
          commissionTotal = Number(commissionTotal) + Number(item.income_3 || 0) + Number(item.income_4 || 0) + Number(item.income_5 || 0)
          item.commissionTotal = commissionTotal
        })
        this.tableData = list
        this.in_company = in_company
        this.loading = false
        this.comparisonLastDate()
      }
    },

    comparisonLastDate() {
      // let calcData = [...this.tableData, ...this.lastData]
      // calcData.forEach(item => {
      //   this.hasContain(item.artist_id, item)
      // })
      // console.log(this.newItems)
      this.tableData.forEach((item) => {
        let oldNum = 0
        let newNum = 0
        let contrast = 0
        let type = null
        this.lastData.forEach(i => {
          if (item.artist_id === i.artist_id) {
            //newNum=当月 oldNum=上月
            newNum = Number(item.income_3) + Number(item.income_4) + Number(item.income_5)
            oldNum = Number(i.income_3) + Number(i.income_4) + Number(i.income_5)
            contrast = ((newNum - oldNum) / (oldNum === 0 ? 1 : oldNum)) * 100
            type = (newNum - oldNum) >= 0
          }
        })
        item.contrast = contrast
        item.type = type
      })
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = ''
      let conditionArr = []
      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }
      // if (this.searchCondition.group_id) {
      //   condition.group_id = this.searchCondition.group_id
      // }
      if (this.searchCondition.group_range) {
        condition.group_range = this.searchCondition.group_range
      }

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
        let platform = this.platforms.find((platform) => {
          if (platform.code === condition.platform_code)
            return platform
        })
        conditionArr.push('【' + platform.name + '】')
      } else {
        conditionArr.push('【全平台】')
      }

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
        conditionArr.push('【' + condition.year + '年】')
      }

      if (this.searchCondition.months.length > 0) {
        condition.months = this.searchCondition.months
        conditionArr.push('【' + condition.months.join('月、') + '月】')
        // this.conditionStr += '  月份：【' + condition.months.join('、') + '】';
      }

      this.conditionStr = conditionArr.join(' ')

      return condition
    },
    selectDept(nodeIds) {
      // console.log(nodeIds)
      // const nodeData = this.$refs['refCompanySelect'].getNodeData()
      // console.log('=========== nodeData ==========')
      // console.log(nodeData)
      // nodeIds.forEach((nodeId) => {
      //   let curr_node = nodeData.find(value => value.dpt_id === nodeId)
      //   console.log(`node:${nodeId},name:${curr_node.name}`)
      // })
      // console.log('=========== user allow dept ==========')
      // console.log(this.userDptInfo)
      // this.searchCondition.group_ids = nodeIds
      // this.filterGroups = val || []
      this.searchCondition.group_range = nodeIds
      this.initData()
    },
    changeMonth() {
      this.rangYm = null
      this.year = null
      if (this.ym && this.ym.indexOf('-') > -1) {
        let ymArr = this.ym.split('-')
        this.searchCondition.year = ymArr[0] * 1//年份
        this.searchCondition.months = [(ymArr[1]) * 1]//月份的集合
      } else {
        this.searchCondition.year = null
        this.searchCondition.months = []
      }
      this.initData()
    },
    choiceQuarter() {
      this.searchCondition.months = [1, 2, 3]//月份的集合
    },
    changeYear() {
      this.ym = null
      this.rangYm = []
      this.quarter = {}
      this.searchCondition.year = this.year
      this.searchCondition.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]//月份的集合
      this.initData()
    },
    changeQuarter() {
      this.rangYm = []
      this.ym = null
      this.searchCondition.months = this.quarter.value//月份的集合
      this.getOverData()
      this.initData()

    },
    async exportExcel() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.downloadRevenueDatum(searchCondition)
        let name = '营收数据'
        if (this.conditionStr) {
          name += `:${this.conditionStr}`
        }
        if (this.$refs['refExportLink'])
          this.$refs['refExportLink'].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    async exportDownload() {
      try {
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          let searchCondition = this.handleSearchCondition()
          let response = await this.$api.downloadRevenueDatum(searchCondition)
          let data = response.data
          let contentType = response.headers['content-type']
          let name = '营收数据'
          if (this.conditionStr) {
            name += `:${this.conditionStr}`
          }

          let blob = new Blob([data], {
            type: contentType
          })
          FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
        } else {
          this.$message.warning('浏览器不支持导出文件')
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }

    },
    async shareRank() {
      // Saving a canvas
      FileSaver.saveAs('https://httpbin.org/image', 'image.jpg')

    },
    goTrendDataList() {
      this.$router.push({
        name: 'revenue_enter'
      })
    },
    moneyFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 2) {
          return
        }
        if (index === 2) {
          sums[index] = '合计:'
          return
        }
        if (['income_3', 'income_4', 'income_5'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            default:
              sums[index] = this.moneyFormat(sums[index])
              break
          }
        }
      })

      return sums
    },
    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
      } else {
        const { href } = this.$router.resolve({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
        window.open(href, '_blank')
      }
    },
    goDeptDetail(id) {
      if (id) {
        const { href } = this.$router.resolve({
          name: 'team',
          query: { group_id: id }
        })
        window.open(href, '_blank')
      } else {
        this.$notify.error('部门ID未知，无法查看详情')
      }
    },
    jumpToKolDetail(artist_id) {
      const { href } = this.$router.resolve({
        name: 'artist_intro',
        params: { artist_id: artist_id }
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style scoped>
.sign_date {
  color: #909399;
  font-weight: 400;
}

.choice-time, .export-excel, .share-rank, .cond {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
}

.el-table .warning-row {
  background: #f9f6f1;
}

.platforms {
  display: flex;
  flex-direction: row;
}

.radio-title {
  font-size: 18px;
  margin-left: 5px;
  line-height: 22px;
}

.fired-tag {
  font-weight: lighter;
  color: red;
}
</style>

<style>
.el-table .warning-row {
  background-color: rgba(255, 242, 204, 0.9);
}

</style>
